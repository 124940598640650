/**
 * @description Validate roles from token
 * @param {string} token
 * @param {array} roles
 * @returns [authenticated: boolean, authorized: boolean]
 */
const validateRoles = (token, roles) => {
  if (token) {
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      // Empty roles means no role is needed
      return [true, roles.length === 0 || roles.includes(decodedToken.role)];
    } catch (error) {
      return [true, false];
    }
  }
  return [false, false];
};

export default validateRoles;

import React, { useEffect, useState } from "react";
import ModalFormScreen from "../../components/Modal/ModalFormScreen";
import SurveyModal from "./SurveyModal";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { useGet } from "helpers/hooks/useGet";
import { usePost } from "helpers/hooks/usePost";
import { ToastService } from "components/Notifications/Toast.service";
import { useHistory } from "react-router-dom";
import { API_ROUTES } from "helpers/constants/routes";

const FIRST_SURVEY_VERSION = 1.1; // Fixed decimal

const Surveys = (props) => {
  const { auth: activeUser } = props;
  const {
    organization: { _id: organizationId },
  } = activeUser;
  const toastService = ToastService();
  const {
    isLoading: isLoadingSurveys,
    data: surveysData,
    execute: executeGetSurveyList,
  } = useGet(API_ROUTES.ORGANIZATION_ADMIN(organizationId).GET_SURVEY_LIST);
  const { isLoading: isLoadingLaunchPost, execute: executeLaunchSurvey } =
    usePost(API_ROUTES.ORGANIZATION_ADMIN(organizationId).POST_SURVEY_LAUNCH);

  const [modalSurvey, setModalSurvey] = useState(false);
  const [nextNumber, setNextNumber] = useState(FIRST_SURVEY_VERSION);
  const latestSurvey = surveysData?.data?.latestSurvey;

  const launchExistingSurvey = () => {
    const latestVersion = latestSurvey?.versionNumber || FIRST_SURVEY_VERSION;
    setNextNumber(Number(latestVersion.toFixed(1)));
    toggleModalSurvey();
  };

  const toggleModalSurvey = () => {
    setModalSurvey(!modalSurvey);
  };

  const cancelModal = () => {
    toggleModalSurvey();
  };

  const handleLaunchNewSurvey = async (formValues) => {
    try {
      await executeLaunchSurvey({ ...formValues, surveyId: latestSurvey._id });
      toastService.success("Survey created successfully");
      executeGetSurveyList();
    } catch (error) {
      toastService.error(
        "Error occurred while trying to create survey. Please try again"
      );
    }
    setModalSurvey(false);
  };

  useEffect(() => {
    try {
      executeGetSurveyList();
    } catch (error) {}
  }, [executeGetSurveyList]);

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Surveys</h3>
              </CardHeader>
              <CardBody>
                {surveysData?.data?.surveys?.length ? (
                  <Row>
                    <Col>
                      {isLoadingSurveys ? (
                        <Spinner color="primary" />
                      ) : (
                        <div>
                          <Pagination>
                            <PaginationItem className="active">
                              <PaginationLink
                                href="#"
                                onClick={(e) => e.preventDefault()}
                              >
                                1
                              </PaginationLink>
                            </PaginationItem>
                          </Pagination>
                          <Table striped hover>
                            <thead className="thead">
                              <tr>
                                <th scope="col">Survey Name</th>
                                <th scope="col">No.</th>
                                <th scope="col">Date Completed</th>
                                <th scope="col">Respondents</th>
                              </tr>
                            </thead>
                            <tbody>
                              {surveysData?.data &&
                                surveysData?.data.surveys?.map(
                                  (surveyCampaign, i) => (
                                    <tr key={i}>
                                      <td>{`${surveyCampaign.survey.surveyName}`}</td>
                                      <td>{`${surveyCampaign.survey.versionNumber}`}</td>
                                      <td>{`${
                                        surveyCampaign.completedAt ||
                                        "Not Completed"
                                      }`}</td>
                                      <td>{`${
                                        surveyCampaign.responses || 0
                                      }`}</td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <h4>No surveys launched at the moment</h4>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    {/** Compare latest version from launched surveys against the global latest version */}
                    {surveysData?.data?.surveys?.reduce(
                      (latest, surveyCampaign) =>
                        (latest = Math.max(
                          surveyCampaign?.survey?.versionNumber,
                          latest
                        )),
                      0
                    ) !== latestSurvey?.versionNumber &&
                    !!latestSurvey?.versionNumber ? (
                      <Button color="primary" onClick={launchExistingSurvey}>
                        {"Launch New Survey"}
                      </Button>
                    ) : (
                      <h4>No new surveys available at the moment</h4>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ModalFormScreen
        confirmButtonText={"Send"}
        confirmActionCallback={handleLaunchNewSurvey}
        isOpen={modalSurvey}
        modalTitle={""}
        cancelCallback={cancelModal}
        toggleCallback={toggleModalSurvey}
        isLoading={isLoadingLaunchPost}
      >
        <SurveyModal organizationId={organizationId} nextNumber={nextNumber} />
      </ModalFormScreen>
    </>
  );
};

export default Surveys;

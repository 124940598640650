import { API_ROUTES } from "helpers/constants/routes";
import { useGet } from "helpers/hooks/useGet";
import React, { useEffect } from "react";
import * as Survey from "survey-react";

const SurveyView = (props) => {
  const { auth: activeUser, match } = props;
  const {
    organization: { _id: organizationId },
    userId,
  } = activeUser;
  const {
    isLoading,
    data: surveyData,
    execute,
  } = useGet(
    API_ROUTES.ORGANIZATION_USER(organizationId).GET_SURVEY_CAMPAIGN(
      userId,
      match.params.surveyId
    )
  );
  const sendDataToServer = (survey) => {
    //send Ajax request to your web server.
    alert("The results are:" + JSON.stringify(survey.data));
  };

  useEffect(() => {
    try {
      execute();
    } catch (error) {}
  }, [execute]);

  return (
    <>
      {surveyData?.data?.survey ? (
        <Survey.Survey
          json={surveyData?.data?.survey}
          onComplete={sendDataToServer}
        />
      ) : null}
    </>
  );
};

export default SurveyView;

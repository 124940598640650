import React from "react";

let activeUser = null;

const subscribers = [];

const unsubscribe = (subscriber) => {
  const index = subscribers.findIndex(subscriber);
  ~index && subscribers.splice(index, 1);
};
const subscribe = (subscriber) => {
  subscribers.push(subscriber);
  return () => unsubscribe(subscriber);
};
const tryFetchActiveUser = () => {
  const userString = sessionStorage.getItem("user");
  return userString ? JSON.parse(userString) : null;
};

export const withAuth = (Component) => {
  return class WithAuth extends React.Component {
    componentDidMount() {
      this.unsubscribe = subscribe(this.forceUpdate.bind(this));
    }
    render() {
      const newProps = {
        ...this.props,
        auth: activeUser || tryFetchActiveUser(),
      };
      return <Component {...newProps} />;
    }
    componentWillUnmount() {
      this.unsubscribe();
    }
  };
};

export const updateUser = (newState) => {
  activeUser = newState;
  sessionStorage.setItem("user", JSON.stringify(activeUser));
  subscribers.forEach((subscriber) => subscriber());
};

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function ToastService() {
  let position = toast.POSITION.TOP_RIGHT;
  return {
    setPosition: (newPosition) => (position = newPosition),

    message: (message) => toast(message),

    success: (message) =>
      toast.success(message, {
        position,
      }),

    error: (message) =>
      toast.error(message, {
        position,
      }),

    warn: (message) =>
      toast.warn(message, {
        position,
      }),

    info: (message) =>
      toast.info(message, {
        position,
      }),
  };
}

import { API_ROUTES } from "helpers/constants/routes";
import { useGet } from "helpers/hooks/useGet";
import React, { useEffect } from "react";
import * as Survey from "survey-react";

const SurveyView = (props) => {
  const { match } = props;
  const {
    isLoading,
    data: surveyData,
    execute,
  } = useGet(
    API_ROUTES.PHRONUS_ADMIN.GET_SURVEY(props.location.state.surveyId)
  );
  const sendDataToServer = (survey) => {
    //send Ajax request to your web server.
    alert("The results are:" + JSON.stringify(survey.data));
  };

  useEffect(() => {
    try {
      execute();
    } catch (error) {}
  }, [execute]);

  return (
    <>
      {surveyData?.data?.survey ? (
        <Survey.Survey
          json={surveyData?.data?.survey}
          onComplete={sendDataToServer}
        />
      ) : null}
    </>
  );
};

export default SurveyView;

import React, { useState } from "react";
import to from "await-to-js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { loginUser } from "services/api/login.service";
import { withRouter } from "react-router-dom";
import { useToken } from "helpers/hooks/useToken";
import { tokenToRouteIndex } from "helpers/auth/tokenToRouteIndex";
import { updateUser } from "helpers/hooks/useActiveUser";

const Login = (props) => {
  const { location, history } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { setToken } = useToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [err, res] = await to(
      loginUser({
        email,
        password,
      })
    );
    if (err) {
      return setErrorMessage(
        err.message || "Unexpected error occurred, please try again."
      );
    }
    setToken(res.accessToken);
    updateUser(res.data);
    if (location.state?.from?.pathname) {
      history.push(location.state.from.pathname);
    } else {
      history.push(tokenToRouteIndex(res.accessToken));
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in</small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
                {errorMessage && <h6 className="text-red"> {errorMessage} </h6>}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default withRouter(Login);

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useToken } from "helpers/hooks/useToken";
import { tokenToRouteIndex } from "helpers/auth/tokenToRouteIndex";

const GuardedRedirectRoute = ({ ...rest }) => {
  const { token } = useToken();
  return (
    <Route
      {...rest}
      render={() => {
        if (!token) {
          return (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: rest.location },
              }}
            />
          );
        }
        const redirectRoute = tokenToRouteIndex(token);
        return <Redirect to={redirectRoute} />;
      }}
    />
  );
};

export default GuardedRedirectRoute;

import React, { useState, useEffect } from "react";
import ModalFormScreen from "../../components/Modal/ModalFormScreen";
import OrganizationModal from "./OrganizationModal";
import { useGet } from "../../helpers/hooks/useGet";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  Button,
  Spinner,
} from "reactstrap";
import { usePost } from "helpers/hooks/usePost";
import { ToastService } from "components/Notifications/Toast.service";
import { usePut } from "helpers/hooks/usePut";
import { API_ROUTES } from "helpers/constants/routes";

const Organizations = () => {
  const toastService = ToastService();

  const [organizationToEdit, setOrganizationToEdit] = useState(null);
  const [modalOrganization, setModalOrganization] = useState(false);
  const toggleModalOrganization = () =>
    setModalOrganization(!modalOrganization);
  const cancelModal = () => {
    setOrganizationToEdit(null);
    toggleModalOrganization();
  };
  const {
    data,
    isLoading,
    execute: executeGet,
  } = useGet(API_ROUTES.PHRONUS_ADMIN.GET_ORGANIZATION_LIST);
  const { isLoading: isLoadingPost, execute: executePost } = usePost(
    API_ROUTES.PHRONUS_ADMIN.POST_CREATE_ORGANIZATION
  );
  const { isLoading: isLoadingPut, execute: executePut } = usePut(
    API_ROUTES.PHRONUS_ADMIN.PUT_UPDATE_ORGANIZATION
  );

  useEffect(() => {
    try {
      executeGet();
    } catch (error) {}
  }, [executeGet]);

  const setOrganizationToEditModal = (organization) => {
    setOrganizationToEdit(organization);
    toggleModalOrganization();
  };

  const handleCreateOrganization = async (formValues) => {
    try {
      const { name, enabled: checkboxEnabled, ...firstContact } = formValues;
      const enabled = checkboxEnabled;
      await executePost({ name, enabled, firstContact });
      toastService.success("Organization created successfully");
      executeGet();
    } catch (error) {
      toastService.error(
        "Error occurred while trying to create an organization. Please try again"
      );
    }
    setModalOrganization(false);
  };

  const handleUpdateOrganization = async (formValues) => {
    try {
      const { name, enabled: checkboxEnabled } = formValues;
      const enabled =
        checkboxEnabled === undefined
          ? organizationToEdit.enabled
          : checkboxEnabled;
      await executePut({ name, enabled }, organizationToEdit["_id"]);
      toastService.success("Organization updated successfully");
      executeGet();
    } catch (error) {}
    setModalOrganization(false);
    setOrganizationToEdit(null);
  };

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Organizations</h3>
              </CardHeader>
              <CardBody>
                {data?.data?.length ? (
                  <Row>
                    <Col>
                      {isLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        <Table>
                          <thead className="thead">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Status</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data &&
                              data.data.map((org, i) => (
                                <tr key={i}>
                                  <td>{`${org.name}`}</td>
                                  <td>{`${
                                    org.enabled ? "Enabled" : "Disabled"
                                  }`}</td>
                                  <td>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() =>
                                        setOrganizationToEditModal(org)
                                      }
                                    >
                                      Edit
                                    </Button>{" "}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <h4>No organizations created at the moment</h4>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Button onClick={toggleModalOrganization}>
                      {"Add New Organization"}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalFormScreen
        confirmButtonText={"Submit"}
        confirmActionCallback={
          organizationToEdit
            ? handleUpdateOrganization
            : handleCreateOrganization
        }
        isOpen={modalOrganization}
        modalTitle={`${organizationToEdit ? "Update" : "New"} Organization`}
        cancelCallback={cancelModal}
        toggleCallback={toggleModalOrganization}
        isLoading={isLoadingPost || isLoadingPut}
      >
        <OrganizationModal currentValues={organizationToEdit} />
      </ModalFormScreen>
    </>
  );
};

export default Organizations;

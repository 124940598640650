import { useState, useCallback } from "react";
import to from "await-to-js";
import { APP_URL } from "config/app.config";
import { useAuthHeader } from "./useAuthHeaders";

export const usePut = (resource) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const authHeader = useAuthHeader();

  const execute = async (data, id) => {
    setIsLoading(true);
    const [err, response] = await to(
      fetch(`${APP_URL}${resource}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: authHeader,
        },
        body: JSON.stringify(data),
      })
    );
    if (err) {
      setIsLoading(false);
      setError(err);
      throw err;
    } else {
      setIsLoading(false);
      const responseData = await response.json();
      if (!response.ok) {
        setError(
          `Request failed with status ${response.status}: ${responseData?.message}`
        );
        throw new Error(
          ` ${
            responseData?.message || "Unexpected error, please try again later."
          }`
        );
      }
      setData(responseData);
      return responseData;
    }
  };
  return {
    isLoading,
    error,
    data,
    execute: useCallback(execute, []),
  };
};

import React, { useState, useEffect } from "react";
import {
  Container,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormText,
  Button,
  Spinner,
  Form,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { API_ROUTES } from "helpers/constants/routes";
import { useGet } from "helpers/hooks/useGet";
import { usePut } from "helpers/hooks/usePut";

import { ToastService } from "components/Notifications/Toast.service";

import { updateUser } from "helpers/hooks/useActiveUser";

const BasicProfile = ({ auth }) => {
  console.log(auth);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const toastService = ToastService();

  const {
    reset,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { isLoading, data, execute } = useGet(
    API_ROUTES.PROFILE(auth.userId).GET_PROFILE
  );

  const { isLoading: isLoadingPut, execute: executePut } = usePut(
    API_ROUTES.PROFILE(auth.userId).PUT_PROFILE
  );

  const { firstName, lastName, email } = data || {};

  const isEmailDisabled = !!email;

  useEffect(() => {
    try {
      execute();
      setDisabledSubmit(false);
    } catch (error) {
      console.log(error);
    }
  }, [execute]);

  useEffect(() => {
    if (data) {
      const { firstName, lastName, email } = data.data;
      reset(data.data);
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("email", email);
    }
  }, [data]);

  const onSubmit = async (data) => {
    try {
      setDisabledSubmit(true);
      const { _id, firstName, lastName, email, role } = data;
      await executePut({ firstName, lastName, email }, _id);
      toastService.success("Profile updated successfully");
      setDisabledSubmit(false);
      updateUser({ email, firstName, lastName, role, userId: _id });
    } catch (e) {
      toastService.error(
        "Error occurred while trying to update profile. Please try again"
      );
    }
  };

  return (
    <Container className="mt--7" fluid>
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader className="bg-transparent">
              <h3 className="mb-0">My Profile</h3>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Spinner />
              ) : (
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="firstName">First Name *</Label>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Input
                              placeholder="First Name"
                              type="text"
                              onChange={onChange}
                              value={value}
                            />
                          )}
                          name="firstName"
                          control={control}
                          rules={{
                            required: "First Name is required",
                          }}
                        />
                        {errors.firstName && (
                          <FormText color="red">
                            {" "}
                            *{errors.firstName.message}{" "}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="lastName">Last Name *</Label>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Input
                              placeholder="Last Name"
                              type="text"
                              onChange={onChange}
                              value={value}
                            />
                          )}
                          name="lastName"
                          control={control}
                          rules={{
                            required: "Last Name is required",
                          }}
                        />
                        {errors.lastName && (
                          <FormText color="red">
                            {" "}
                            *{errors.lastName.message}{" "}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="email">Email *</Label>
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Input
                              placeholder="Email"
                              type="text"
                              onChange={onChange}
                              value={value}
                              defaultValue={email}
                              disabled={isEmailDisabled}
                            />
                          )}
                          name="email"
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid Email format",
                            },
                          }}
                          control={control}
                        />
                        {errors.email && (
                          <FormText color="red">
                            {" "}
                            *{errors.email.message}{" "}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="text-right text-md-right"
                      md={{
                        offset: 4,
                        size: 4,
                      }}
                    >
                      {isLoadingPut && <Spinner />}
                    </Col>
                    <Col className="text-center text-md-right" md="4">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={disabledSubmit}
                      >
                        {"Update Profile"}
                      </Button>{" "}
                    </Col>
                  </Row>
                </Form>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BasicProfile;

export const AVAILABLE_ROLES = [
  "phronusAdmin",
  "phronusUser",
  "organizationAdmin",
  "organizationUser",
];
export const ROLES = {
  PHRONUS_ADMIN: "phronusAdmin",
  PHRONUS_USER: "phronusUser",
  ORGANIZATION_ADMIN: "organizationAdmin",
  ORGANIZATION_USER: "organizationUser",
};

/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import PhronusAdminIndex from "views/phronus-admin/Index.js";
import PhronusAdminSurveys from "views/phronus-admin/Surveys";
import PhronusAdminSurveyView from "views/phronus-admin/SurveyView";
import PhronusAdminOrganizations from "views/phronus-admin/Organizations";
import PhronusAdminSettings from "views/phronus-admin/Settings";
import Login from "views/login/Login.js";
import OrganizationAdminIndex from "views/organization-admin/Index.js";
import OrganizationAdminSurveys from "views/organization-admin/Surveys.js";
import OrganizationAdminSettings from "views/organization-admin/Settings.js";
import OrganizationAdminUsers from "views/organization-admin/Users";
import OrganizationUserIndex from "views/organization-user/Index.js";
import OrganizationUserSurvey from "views/organization-user/SurveyView";
import OrganizationUserSettings from "views/organization-user/Settings.js";

import OrganizationAdminUserProfile from "views/organization-admin/UserProfile";
import OrganizationUserUserProfile from "views/organization-user/UserProfile";
import PhronusAdminUserProfile from "views/phronus-admin/UserProfile";

import { ROLES } from "helpers/constants/availableRoles";

var routes = {
  auth: [
    {
      path: "/login",
      name: "Login",
      icon: "ni ni-key-25 text-info",
      component: Login,
      auth: false,
      roles: [],
    },
  ],
  "phronus-admin": [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2 text-primary",
      component: PhronusAdminIndex,
      category: "main",
      auth: true,
      roles: [ROLES.PHRONUS_ADMIN],
    },
    {
      path: "/surveys",
      name: "Surveys",
      icon: "ni ni-planet text-blue",
      component: PhronusAdminSurveys,
      category: "main",
      auth: true,
      roles: [ROLES.PHRONUS_ADMIN],
    },
    {
      path: "/survey/:surveyId",
      name: "Survey",
      icon: "ni ni-planet text-blue",
      component: PhronusAdminSurveyView,
      auth: true,
      roles: [ROLES.PHRONUS_ADMIN],
    },
    {
      path: "/organizations",
      name: "Organizations",
      icon: "ni ni-planet text-orange",
      component: PhronusAdminOrganizations,
      category: "main",
      auth: true,
      roles: [ROLES.PHRONUS_ADMIN],
    },
    {
      path: "/settings",
      name: "Settings",
      icon: "ni ni-single-02 text-yellow",
      component: PhronusAdminSettings,
      category: "secondary",
      auth: true,
      roles: [ROLES.PHRONUS_ADMIN],
    },
    {
      path: "/user-profile",
      name: "My Profile",
      component: PhronusAdminUserProfile,
      auth: true,
      roles: [ROLES.PHRONUS_ADMIN],
    },
  ],
  "organization-admin": [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2 text-primary",
      component: OrganizationAdminIndex,
      auth: true,
      roles: [ROLES.ORGANIZATION_ADMIN],
      category: "main",
    },
    {
      path: "/surveys",
      name: "Surveys",
      icon: "ni ni-bullet-list-67 text-blue",
      component: OrganizationAdminSurveys,
      layout: "/admin",
      category: "main",
      auth: true,
      roles: [ROLES.ORGANIZATION_ADMIN],
    },
    {
      path: "/settings",
      name: "Settings",
      icon: "ni ni-settings-gear-65",
      component: OrganizationAdminSettings,
      layout: "/admin",
      auth: true,
      roles: [ROLES.ORGANIZATION_ADMIN],
      category: "secondary",
    },
    {
      path: "/users",
      name: "Users",
      icon: "ni ni-circle-08 text-blue",
      component: OrganizationAdminUsers,
      auth: true,
      layout: "/admin",
      roles: [ROLES.ORGANIZATION_ADMIN],
      category: "main",
    },
    {
      path: "/user-profile",
      name: "My Profile",
      component: OrganizationAdminUserProfile,
      auth: true,
      roles: [ROLES.ORGANIZATION_ADMIN],
    },
  ],
  "organization-user": [
    {
      path: "/index",
      name: "Dashboard",
      icon: "ni ni-tv-2 text-primary",
      component: OrganizationUserIndex,
      auth: true,
      roles: [ROLES.ORGANIZATION_USER],
      category: "main",
    },
    {
      path: "/survey/:surveyId",
      name: "Survey",
      icon: "ni ni-settings-gear-65",
      component: OrganizationUserSurvey,
      layout: "/user",
      auth: true,
      roles: [ROLES.ORGANIZATION_USER],
      category: "tertiary",
    },
    {
      path: "/settings",
      name: "Settings",
      icon: "ni ni-settings-gear-65",
      component: OrganizationUserSettings,
      layout: "/user",
      auth: true,
      roles: [ROLES.ORGANIZATION_USER],
      category: "secondary",
    },
    {
      path: "/user-profile",
      name: "My Profile",
      component: OrganizationUserUserProfile,
      auth: true,
      roles: [ROLES.ORGANIZATION_USER],
    },
  ],
};

export default routes;

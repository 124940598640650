/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as Survey from "survey-react";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "react-toastify/dist/ReactToastify.css";

import OrganizationAdminLayout from "layouts/OrganizationAdminLayout.js";
import OrganizationUserLayout from "layouts/OrganizationUserLayout.js";
import PhronusAdminLayout from "layouts/PhronusAdminLayout.js";
import AuthLayout from "layouts/Auth.js";
import GuardedRoute from "./GuardedRoute.js";
import { ROLES } from "helpers/constants/availableRoles.js";
import NotAuthorized from "views/errors/NotAuthorized.js";
import GuardedRedirectRoute from "GuardedRedirectRoute.js";
import ErrorPage from "views/errors/ErrorPage.js";

Survey.StylesManager.applyTheme("modern");
function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <GuardedRedirectRoute exact path="/" />
          <GuardedRoute
            path="/phronus-admin"
            component={PhronusAdminLayout}
            roles={[ROLES.PHRONUS_ADMIN]}
          />
          <GuardedRoute
            path="/organization-admin"
            component={OrganizationAdminLayout}
            roles={[ROLES.ORGANIZATION_ADMIN]}
          />
          <GuardedRoute
            path="/organization-user"
            component={OrganizationUserLayout}
            roles={[ROLES.ORGANIZATION_USER]}
          />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/not-authorized" component={NotAuthorized} />
          <Route path="/error" component={ErrorPage} />
        </Switch>
      </BrowserRouter>

      <ToastContainer />
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

import React, { useEffect } from "react";

import { FormGroup, Label, Input, Row, Col, FormText } from "reactstrap";
import { Controller } from "react-hook-form";

const UserModal = (props) => {
  const { errors, setValue, currentValues, control, reset } = props;
  const { firstName, lastName, email } = currentValues || {};
  const isEmailDisabled = !!email;
  useEffect(() => {
    reset({
      firstName: "",
      lastName: "",
      email: "",
    });
  }, []);
  useEffect(() => {
    if (currentValues) {
      const { firstName, lastName, email } = currentValues;
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("email", email);
    }
  }, [currentValues]);

  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Input
                  placeholder="First Name"
                  type="text"
                  onChange={onChange}
                  value={value}
                  defaultValue={firstName}
                />
              )}
              name="firstName"
              control={control}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Input
                  placeholder="Last Name"
                  type="text"
                  onChange={onChange}
                  value={value}
                  defaultValue={lastName}
                />
              )}
              name="lastName"
              control={control}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="email">Email*</Label>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Input
                  placeholder="Email"
                  type="text"
                  onChange={onChange}
                  value={value}
                  defaultValue={email}
                  disabled={isEmailDisabled}
                />
              )}
              name="email"
              rules={{
                required: "Email is required",
              }}
              control={control}
            />
            {errors.email && (
              <FormText color="red"> *{errors.email.message} </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default UserModal;

import React from "react";
import { Route, Redirect } from "react-router-dom";
import validateRoles from "helpers/auth/validateRoles";
import { useToken } from "helpers/hooks/useToken";
import { withAuth } from "helpers/hooks/useActiveUser";

const GuardedRoute = ({ component: Component, roles = [], ...rest }) => {
  const { token } = useToken();
  return (
    <Route
      {...rest}
      render={(props) => {
        const [authenticated, authorized] = validateRoles(token, roles);
        if (authenticated) {
          if (authorized) {
            const ComponentWithAuth = withAuth(({ auth }) => {
              return <Component {...props} auth={auth} />;
            });
            return <ComponentWithAuth />;
          }
          return <Redirect to="/not-authorized" />;
        }
        return (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default GuardedRoute;

import React, { useEffect, useState } from "react";
import ModalFormScreen from "../../components/Modal/ModalFormScreen";
import NewSurveyModal from "./NewSurveyModal";
import LaunchSurveyModal from "./LaunchSurveyModal";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { useGet } from "helpers/hooks/useGet";
import { usePostFormData } from "helpers/hooks/usePostFormData";
import { usePost } from "helpers/hooks/usePost";
import { ToastService } from "components/Notifications/Toast.service";
import { useHistory } from "react-router-dom";
import { API_ROUTES } from "helpers/constants/routes";

const FIRST_SURVEY_VERSION = 1.1; // Fixed decimal

const Surveys = () => {
  const toastService = ToastService();
  const history = useHistory();
  const {
    isLoading,
    data: surveysData,
    execute,
  } = useGet(API_ROUTES.PHRONUS_ADMIN.GET_SURVEY_LIST);
  const { isLoading: isLoadingCreatePost, execute: executeFormDataPost } =
    usePostFormData(API_ROUTES.PHRONUS_ADMIN.POST_CREATE_SURVEY);
  const { isLoading: isLoadingLaunchPost, execute: executePost } = usePost(
    API_ROUTES.PHRONUS_ADMIN.POST_LAUNCH_SURVEY
  );
  const [modalSurvey, setModalSurvey] = useState(false);
  const [isNewSurvey, setIsNewSurvey] = useState(true);
  const [nextNumber, setNextNumber] = useState(FIRST_SURVEY_VERSION);

  const viewSurvey = (surveyId) => {
    history.push({
      pathname: `/phronus-admin/survey/${surveyId}`,
      state: {
        surveyId,
      },
    });
  };

  const launchNewSurvey = () => {
    const latestVersion =
      surveysData?.data.reduce(
        (latest, survey) => (latest = Math.max(survey.versionNumber, latest)),
        0
      ) || 0;
    setNextNumber(Number((Math.floor(latestVersion + 1) + 0.1).toFixed(1)));
    setIsNewSurvey(true);
    toggleModalSurvey();
  };

  const launchExistingSurvey = () => {
    const latestVersion =
      surveysData?.data.reduce(
        (latest, survey) => (latest = Math.max(survey.versionNumber, latest)),
        0
      ) || 0;
    setNextNumber(Number((latestVersion + 0.1).toFixed(1)));
    setIsNewSurvey(false);
    toggleModalSurvey();
  };

  const toggleModalSurvey = () => {
    setModalSurvey(!modalSurvey);
  };

  const cancelModal = () => {
    toggleModalSurvey();
  };

  useEffect(() => {
    try {
      execute();
    } catch (error) {}
  }, [execute]);

  const handleSubmitNewSurvey = async (formValues) => {
    try {
      const formData = new FormData();
      formData.append("surveyFile", formValues.surveyJSON);
      formData.append(
        "surveyData",
        JSON.stringify({
          versionNumber: nextNumber,
          ...formValues,
        })
      );
      await executeFormDataPost(formData);
      toastService.success("Survey created successfully");
      execute();
    } catch (error) {
      toastService.error(
        "Error occurred while trying to create survey. Please try again"
      );
    }
    setModalSurvey(false);
  };

  const handleLaunchExistingSurvey = async (formValues) => {
    try {
      await executePost({
        versionNumber: nextNumber,
        ...formValues,
      });
      toastService.success("Survey created successfully");
      execute();
    } catch (error) {
      toastService.error(
        "Error occurred while trying to create survey. Please try again"
      );
    }
    setModalSurvey(false);
  };
  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Surveys</h3>
              </CardHeader>
              <CardBody>
                {surveysData?.data?.length ? (
                  <Row>
                    <Col>
                      {isLoading ? (
                        <Spinner color="primary" />
                      ) : (
                        <div>
                          <Pagination>
                            <PaginationItem className="active">
                              <PaginationLink
                                href="#"
                                onClick={(e) => e.preventDefault()}
                              >
                                1
                              </PaginationLink>
                            </PaginationItem>
                          </Pagination>
                          <Table striped hover>
                            <thead className="thead">
                              <tr>
                                <th scope="col">Survey Name</th>
                                <th scope="col">No.</th>
                                <th scope="col">Date Created</th>
                                <th scope="col">Respondents</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {surveysData?.data &&
                                surveysData.data.map((survey, i) => (
                                  <tr key={i}>
                                    <td>{`${survey.surveyName}`}</td>
                                    <td>{`${survey.versionNumber}`}</td>
                                    <td>{`${survey.createdAt}`}</td>
                                    <td>{`${survey.respondents || 0}`}</td>
                                    <td>
                                      <Button
                                        color="primary"
                                        size="sm"
                                        onClick={(e) => viewSurvey(survey.id)}
                                      >
                                        View
                                      </Button>{" "}
                                      <Button color="danger" size="sm" disabled>
                                        Delete
                                      </Button>{" "}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <h4>No surveys created at the moment</h4>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    {surveysData?.data?.length ? (
                      <Button color="primary" onClick={launchExistingSurvey}>
                        {"Launch Next Version"}
                      </Button>
                    ) : null}
                    <Button onClick={launchNewSurvey}>
                      {"Create New Survey"}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalFormScreen
        confirmButtonText={"Submit"}
        confirmActionCallback={
          isNewSurvey ? handleSubmitNewSurvey : handleLaunchExistingSurvey
        }
        isOpen={modalSurvey}
        modalTitle={`${isNewSurvey ? "New" : "Launch"} Survey`}
        cancelCallback={cancelModal}
        toggleCallback={toggleModalSurvey}
        isLoading={isLoadingCreatePost || isLoadingLaunchPost}
      >
        {isNewSurvey ? (
          <NewSurveyModal nextNumber={nextNumber} />
        ) : (
          <LaunchSurveyModal nextNumber={nextNumber} />
        )}
      </ModalFormScreen>
    </>
  );
};

export default Surveys;

import React from "react";
import { Link, withRouter } from "react-router-dom";

function ErrorPage() {
  return (
    <div className="wrapper">
      <div className="page-header error-page">
        <div className="page-header-image"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="lead">An error occurred :(</p>
              <h4 className="description text-default">
                Please, try again later.
              </h4>
              <Link to="/">Go Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ErrorPage);

import React, { useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";

const ModalFormScreen = (props) => {
  const {
    children,
    confirmButtonText,
    isOpen,
    toggleCallback,
    cancelCallback,
    confirmActionCallback,
    modalTitle,
    isLoading,
  } = props;

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const handleCancel = (e) => {
    reset();
    cancelCallback(e);
  };
  const onSubmit = (data) => {
    reset();
    confirmActionCallback(data);
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggleCallback} backdrop={"static"}>
        <ModalHeader toggle={toggleCallback}>{modalTitle}</ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {React.cloneElement(children, {
              register,
              errors,
              control,
              reset,
              setValue,
              getValues,
              setDisabledSubmit,
            })}
          </ModalBody>
          <ModalFooter>
            {isLoading && <Spinner />}
            <Button
              color="primary"
              type="submit"
              disabled={isLoading || disabledSubmit}
            >
              {confirmButtonText || "Save"}
            </Button>{" "}
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default ModalFormScreen;

import { ROLES } from "helpers/constants/availableRoles";

const ROLE_TO_ROUTE_INDEX = {
  [ROLES.PHRONUS_ADMIN]: "/phronus-admin",
  [ROLES.PHRONUS_USER]: "/phronus-user",
  [ROLES.ORGANIZATION_ADMIN]: "/organization-admin",
  [ROLES.ORGANIZATION_USER]: "/organization-user",
};

export const tokenToRouteIndex = (token) => {
  try {
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    return ROLE_TO_ROUTE_INDEX[decodedToken.role];
  } catch (error) {
    return "/error";
  }
};

import React, { useState, useEffect } from "react";
import ModalFormScreen from "../../components/Modal/ModalFormScreen";
import UserModal from "./UserModal";
import { useGet } from "../../helpers/hooks/useGet";
import { usePost } from "../../helpers/hooks/usePost";
import { usePut } from "../../helpers/hooks/usePut";
import { useDelete } from "../../helpers/hooks/useDelete";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { ToastService } from "components/Notifications/Toast.service";
import { API_ROUTES } from "helpers/constants/routes";
import { ROLES } from "helpers/constants/availableRoles";

const Users = (props) => {
  const {
    auth: { organization },
  } = props;
  const toastService = ToastService();
  const [modalUser, setModalUser] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);

  const { isLoading, data, execute } = useGet(
    API_ROUTES.ORGANIZATION_ADMIN(organization._id).GET_USER_LIST
  );
  const { isLoading: isLoadingPost, execute: executePost } = usePost(
    API_ROUTES.ORGANIZATION_ADMIN(organization._id).POST_CREATE_USER
  );
  const { isLoading: isLoadingPut, execute: executePut } = usePut(
    API_ROUTES.ORGANIZATION_ADMIN(organization._id).PUT_UPDATE_USER
  );
  const { isLoading: isLoadingDelete, execute: executeDelete } = useDelete(
    API_ROUTES.ORGANIZATION_ADMIN(organization._id).DELETE_USER
  );

  const toggleModalUser = () => setModalUser(!modalUser);

  const cancelModal = () => {
    setUserToEdit(null);
    toggleModalUser();
  };

  const setUserToEditModal = (user) => {
    setUserToEdit(user);
    toggleModalUser();
  };

  const handleSubmit = async (formValues) => {
    try {
      await executePost(formValues);
      execute();
      toastService.success("User created successfully");
    } catch (error) {
      toastService.error(
        `Error occurred while trying to create the user: ${error?.message}`
      );
    }
    setModalUser(false);
  };

  const handleSubmitEdit = async (formValues) => {
    try {
      await executePut(formValues, userToEdit["_id"]);
      execute();
      toastService.success("User updated successfully");
    } catch (error) {
      toastService.error(
        `Error occurred while trying to update the user: ${error?.message}`
      );
    }
    setModalUser(false);
    setUserToEdit(null);
  };

  const handleDelete = async (userId) => {
    try {
      await executeDelete([userId]);
      execute();
      toastService.success("User deleted successfully");
    } catch (error) {
      toastService.error(
        "Error occurred while trying to delete the user. Please try again"
      );
    }
    setModalUser(false);
  };

  useEffect(() => {
    try {
      execute();
    } catch (error) {}
  }, [execute]);

  return (
    <>
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Users</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    {isLoading ? (
                      <Spinner color="primary" />
                    ) : (
                      <div>
                        <Pagination>
                          <PaginationItem className="active">
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              1
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              2
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                        <Table striped hover>
                          <thead className="thead">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data &&
                              data.data.map((user, i) => (
                                <tr key={i}>
                                  <td>{`${user.firstName} ${user.lastName}`}</td>
                                  <td>{`${user.email}`}</td>
                                  <td>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => setUserToEditModal(user)}
                                    >
                                      Edit
                                    </Button>{" "}
                                    <Button
                                      color="danger"
                                      size="sm"
                                      onClick={() => handleDelete(user._id)}
                                    >
                                      Delete
                                    </Button>{" "}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mt-3">
                      <Button color="primary" onClick={toggleModalUser}>
                        {"Add New User"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalFormScreen
        confirmActionCallback={userToEdit ? handleSubmitEdit : handleSubmit}
        isOpen={modalUser}
        modalTitle={`${userToEdit ? "Edit" : "New"} User`}
        cancelCallback={cancelModal}
        isLoading={isLoadingPost || isLoadingPut}
      >
        <UserModal register={null} currentValues={userToEdit} />
      </ModalFormScreen>
    </>
  );
};

export default Users;

/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import OrganizationUserNavbar from "components/Navbars/OrganizationUserNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import OrganizationUserSidebar from "components/Sidebar/OrganizationUserSidebar.js";

import routes from "routes.js";
import GuardedRoute from "GuardedRoute.js";
import { withAuth } from "helpers/hooks/useActiveUser";

const OrganizationUserLayout = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    const layout = "organization-user";
    return routes[layout].map((prop, key) => {
      const { path, component: Component, roles } = prop;
      return prop.auth ? (
        <GuardedRoute
          path={`/${layout}${path}`}
          component={Component}
          key={key}
          roles={roles}
        />
      ) : (
        <Route path={`/${layout}${path}`} component={Component} key={key} />
      );
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const NavBar = withAuth(({ auth }) => {
    return (
      <OrganizationUserNavbar
        {...props}
        brandText={getBrandText(props.location.pathname)}
        auth={auth}
      />
    );
  });

  return (
    <>
      <OrganizationUserSidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/organization-user/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <NavBar />
        <div className="header bg-gradient-info pt-md-6"></div>
        <Switch>
          {getRoutes(routes)}
          <Redirect to="/organization-user/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default withRouter(OrganizationUserLayout);

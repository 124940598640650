export const API_ROUTES = {
  PROFILE: (userId) => ({
    PUT_PROFILE: `profile`,
    GET_PROFILE: `profile/${userId}`,
  }),
  PHRONUS_ADMIN: {
    GET_ORGANIZATION_LIST: `admin/organization/list`,
    POST_CREATE_ORGANIZATION: `admin/organization`,
    PUT_UPDATE_ORGANIZATION: `admin/organization`,
    GET_SURVEY_LIST: "admin/survey/list",
    POST_CREATE_SURVEY: `admin/survey`,
    POST_LAUNCH_SURVEY: `admin/survey/launch`,
    GET_SURVEY: (surveyId) => `admin/survey/${surveyId}`,
  },
  ORGANIZATION_ADMIN: (organizationId) => ({
    GET_USER_LIST: `organization/${organizationId}/user/list`,
    GET_SURVEY_LIST: `organization/${organizationId}/survey/list`,
    POST_SURVEY_LAUNCH: `organization/${organizationId}/survey/launch`,
    POST_CREATE_USER: `organization/${organizationId}/user`,
    PUT_UPDATE_USER: `organization/${organizationId}/user`,
    DELETE_USER: `organization/${organizationId}/user`,
  }),
  ORGANIZATION_USER: (organizationId) => ({
    GET_SURVEY_CAMPAIGN: (userId, campaignId) =>
      `organization/${organizationId}/user/${userId}/campaign/${campaignId}`,
  }),
};

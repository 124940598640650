import { APP_URL } from "config/app.config";

export function loginUser(credentials) {
  return fetch(`${APP_URL}login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then(async (response) => {
    if (!response.ok) {
      const res = await response.json();
      return Promise.reject({
        status: response.status,
        message: res.message || "",
      });
    }
    return response.json();
  });
}

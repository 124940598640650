import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

import { FormGroup, Label, Input, Row, Col, FormText } from "reactstrap";

const OrganizationModal = (props) => {
  const { register, errors, setValue, currentValues, control, reset } = props;
  const { name, enabled = true } = currentValues || {};
  useEffect(() => {
    reset({
      name: "",
      enabled: true,
    });
  }, []);
  useEffect(() => {
    if (currentValues) {
      const { name, enabled } = currentValues;
      setValue("name", name);
      setValue("enabled", enabled);
    }
  }, [currentValues]);
  return (
    <>
      <Row>
        <Col md="12">
          <FormGroup>
            <Label for="organization-name">Organization Name*</Label>
            <Controller
              render={({ field: { onChange, value } }) => (
                <Input
                  id="organization-name"
                  placeholder="Organization Name"
                  type="text"
                  onChange={onChange}
                  value={value}
                  defaultValue={name}
                />
              )}
              rules={{ required: "Organization name is required" }}
              name="name"
              control={control}
            />
            {errors.name && (
              <FormText color="red"> *{errors.name.message} </FormText>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FormGroup check>
            <Label check>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="checkbox"
                    onChange={onChange}
                    value={value}
                    defaultChecked={enabled}
                  />
                )}
                name="enabled"
                control={control}
              />
              Enabled
            </Label>
          </FormGroup>
        </Col>
      </Row>
      {!currentValues?._id && (
        <>
          <hr />
          <h2> Initial admin account </h2>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="firstName">First Name</Label>

                <Input
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  {...register("firstName")}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  {...register("lastName")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="email">Email*</Label>
                <Input
                  name="email"
                  placeholder="Email"
                  type="email"
                  {...register("email", {
                    required: "Initial admin user: email is required",
                  })}
                />
                {errors.email && (
                  <FormText color="red"> *{errors.email.message} </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default OrganizationModal;

import React from "react";
// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

const Settings = () => {
  return (
    <>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Settings</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="12" md="6">
                    <h1>Under Construction!</h1>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
